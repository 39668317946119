import React from 'react'
import PlayStation from '../../images/PlayStation4Pro.jpg'
import Xbox from '../../images/XboxOneX.jpg'
import { LogoCircle, Typography } from '../utils'
import { LowBudgetBuildComponent } from './LowBudgetBuildComponent'
import styled from 'styled-components'

const consoles = [
  {
    name: 'PlayStation 4 Pro',
    img: PlayStation,
    type: 'Sony',
    provider: 'Amazon',
    link: 'https://amzn.to/2tY0wI4',
  },
  {
    name: 'Xbox One X',
    img: Xbox,
    type: 'Microsoft',
    provider: 'Amazon',
    link: 'https://amzn.to/2GJiBw9',
  },
]

export const LowBudgetBuild = () => {
  return (
    <Container>
      <Logo />
      <Typography variant="h1">Sorry :&#40;</Typography>
      <Typography>
        Mit diesem Budget oder weniger ist es einfach nicht möglich, einen
        sinnvollen Gaming-PC zusammenzustellen. Vielleicht solltest du besser an
        eine Konsole denken!
      </Typography>
      <ConsoleWrapper>
        {consoles.map((data) => (
          <LowBudgetBuildComponent
            key={data.name}
            name={data.name}
            imageUrl={data.img}
            affiliateLink={data.link}
            provider={data.provider}
          />
        ))}
      </ConsoleWrapper>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 5rem);
  margin: 5rem auto;
  max-width: 420px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    max-width: 1024px;
    margin: 5rem auto 0;
    padding-top: 2rem;
  }

  h1 {
    margin-bottom: 1rem;
  }
  p {
    max-width: 520px;
  }
`

const Logo = styled(LogoCircle)`
  width: 6rem;
  height: auto;
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    width: 8rem;
  }
`
const ConsoleWrapper = styled.div`
  margin-top: 1rem;
`
