import React from 'react'
import { LowBudgetBuild } from '../components/LowBudgetBuild/LowBudgetBuild'
import { Seo } from '../components/utils'

export default function LowBudgetBuildPage() {
  return (
    <>
      <Seo title="Dein Build für 400€" />
      <LowBudgetBuild />
    </>
  )
}
