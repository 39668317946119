import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { ExternalLink, ShoppingCart } from 'react-feather'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import useMedia from 'use-media'
import { LinkButton, Typography, Link, rgba } from '../utils'

export const LowBudgetBuildComponent = ({
  imageUrl,
  name,
  provider,
  isPlaceholder,
  affiliateLink,
  gridColumn,
}) => {
  const isLargeScreen = useMedia({ minWidth: 768 })
  return (
    <Container gridColumn={gridColumn} isPlaceholder={isPlaceholder}>
      <ImgWrapper
        href={`${affiliateLink}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
        className="build-img-container"
      >
        <motion.img alt={`${name} Component`} srcSet={imageUrl} />
      </ImgWrapper>

      <NameWrapper>
        <Typography variant="h3" id="build-componentName">
          {name}
        </Typography>
      </NameWrapper>

      {!isLargeScreen ? (
        <ShopButton
          variant="filled"
          size="small"
          fullWidth
          to={affiliateLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {provider} <ExternalLink size={16} />
        </ShopButton>
      ) : (
        <>
          <TextWrapper>
            <Typography variant="label" htmlFor="build-providerName">
              Anbieter
            </Typography>
            <Link
              to={affiliateLink}
              target="_blank"
              rel="noopener noreferrer"
              color="rgba(255,255,255,0.5)"
            >
              <ShoppingCart />
              {provider}
            </Link>
          </TextWrapper>
          {provider !== 'Platzhalter' && (
            <ShopButtonLarge
              // variant="filled"
              variant="text"
              size="small"
              to={affiliateLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Zu {provider} <ExternalLink />
            </ShopButtonLarge>
          )}
        </>
      )}
    </Container>
  )
}
const Container = styled(motion.section)`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 100px 1fr auto;
  column-gap: 1rem;
  row-gap: 0.5rem;
  padding: 0.75rem 1rem 1rem;
  border-radius: 12px;
  @media (min-width: 768px) {
    row-gap: 1rem;
    grid-template-columns: 150px minmax(180px, 200px) auto;
  }
`
const ImgWrapper = styled(motion(OutboundLink))`
  grid-column: 1;
  grid-row: 1 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100px;
  height: 100%;

  background: white;
  border-radius: 8px;

  img {
    width: 90%;
    height: 90%;
    object-fit: contain;
  }
  @media (min-width: 768px) {
    width: 150px;
    height: 150px;
  }
`
const NameWrapper = styled.div`
  grid-column: 2 / 4;
  grid-row: 1;
  align-self: flex-end;
  @media (max-width: 767px) {
    grid-column: 2 / 4;
    h3 {
      font-size: 1.25rem;
    }
  }
`

const ShopButton = styled(LinkButton)`
  grid-column: 2;
  grid-row: 2;
  line-height: 100%;
  display: inline-flex;
  align-items: end;
  svg {
    margin-left: 8px;
  }
`
const ShopButtonLarge = styled(LinkButton)`
  grid-column: 3;
  grid-row: 2;
  font-size: 1rem;
  justify-self: flex-end;
  svg {
    margin-left: 8px;
  }
  color: white;
  &:visited {
    color: white;
  }
`

const TextWrapper = styled.div`
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.25rem;
  align-items: center;
  align-self: center;

  svg {
    margin-right: 0.5rem;
    height: 1.125rem;
    width: 1.125rem;
  }
  label {
    color: ${({ theme }) => rgba(theme.color.white, 0.7)};
  }
  a,
  a:visited {
    color: ${({ theme }) => rgba(theme.color.white, 1)};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => rgba(theme.color.white, 1)};
  }
  a {
    padding: 0.25rem 0 0rem;
    font-family: ${({ theme }) => theme.font.secondary};
  }
`
// <div className="low-budget-build-card">
//   <a
//     href={`${props.componentProviderLink}`}
//     target="_blank"
//     rel="noopener noreferrer nofollow"
//     className="low-budget-build-img-container"
//   >
//     <img
//       alt={props.componentName + ' Component'}
//       src={props.componentImage}
//     />
//   </a>

//   <div className="low-budget-build-name-container">
//     <label className="low-budget-build-label" htmlFor="build-componentName">
//       {props.componentType}
//     </label>
//     <h2 className="low-budget-build-componentName" id="build-componentName">
//       {props.componentName}
//     </h2>
//   </div>
//   <div className="low-budget-build-provider-container">
//     <label className="low-budget-build-label" htmlFor="build-providerName">
//       Anbieter
//     </label>
//     <h3 className="low-budget-build-providerName" id="build-providerName">
//       <a
//         href={`${props.componentProviderLink}`}
//         target="_blank"
//         rel="noopener noreferrer nofollow"
//       >
//         {props.componentProvider}
//       </a>
//     </h3>
//   </div>
// </div>
